import { legacyPutUserAddress } from '@pumpkincare/user';

import { setBillingAddress } from '../../user';

export default function updateBillingInformation(userId, billingAddress) {
  return dispatch => {
    const payload = {
      type: 'billing',
      id: billingAddress.id,
      first_name: billingAddress.firstName,
      last_name: billingAddress.lastName,
      street_1: billingAddress.street1,
      street_2: billingAddress.street2,
      zipcode: billingAddress.zipcode,
      city: billingAddress.city,
      state: billingAddress.stateProvince,
    };

    return legacyPutUserAddress(userId, payload).then(response => {
      dispatch(
        setBillingAddress({
          type: 'billing',
          id: response.data.body.id,
          firstName: response.data.body.first_name,
          lastName: response.data.body.last_name,
          street1: response.data.body.street_1,
          street2: response.data.body.street_2,
          zipcode: response.data.body.zipcode,
          city: response.data.body.city,
          stateProvince: response.data.body.state_province,
        })
      );
    });
  };
}
