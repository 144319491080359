import { getActivePlan, isPuppyPrevent } from '@pumpkincare/plans';
import {
  calculateAndFormatISODate,
  dateIsAfter,
  getIsPuppy,
} from '@pumpkincare/shared';

import { getActivePolicy } from '../../user';
import validateClaimsDate from './validate-claim-dates';

function validateInvoices({ fileList, errors }) {
  if (fileList.length === 0) {
    errors.itemizedInvoice = {
      title: 'Itemized invoice',
      message: 'This field is required.',
    };
  }

  if (fileList.some(item => item.error)) {
    errors.itemizedInvoice = { title: 'Itemized invoice' };
  }
}

function validateMedicalRecords({ medicalRecordsFileList, errors }) {
  if (medicalRecordsFileList.some(item => item.error)) {
    errors.medicalRecords = { title: 'Medical records' };
  }
}

function validateClinicSelection({ selectedVet, errors }, { shouldValidateClinic }) {
  if (shouldValidateClinic && !selectedVet) {
    errors.clinic = { title: 'Which clinic' };
  }
}

function validateMultipleClinics(
  { isInsurance, isMultiVet, errors },
  { shouldValidateMultiVetAndOngoing }
) {
  if (
    shouldValidateMultiVetAndOngoing &&
    isInsurance &&
    typeof isMultiVet !== 'boolean'
  ) {
    errors.multiClinics = { title: 'Were multiple clinics visited' };
  }
}

function validateDateList({
  dateList,
  isPreventiveEssentials,
  isInsurance,
  activePlan,
  insuranceStartDate,
  errors,
}) {
  const dateListValidated = dateList.map(dateItem => {
    // deep nested objects and object permanence in JS :)
    const item = { ...dateItem };

    /*
     if isPEP:
     - puppy claims are valid regardless of date
     - otherwise claims can be 14 days before effective date
     - can ignore date validation if also isIns (claims team will handle)
     */
    if (isPreventiveEssentials) {
      if (isPuppyPrevent(activePlan.plan)) {
        return item;
      }

      const pepInsuranceStartDate = calculateAndFormatISODate(
        activePlan.plan_effective_date,
        '-14d'
      );

      const pepResult = validateClaimsDate({
        dateStr: item.dateOfVisit,
        dateLabel: 'Date of visit',
        insuranceStartDateStr: pepInsuranceStartDate,
      });

      if (pepResult !== true) {
        item.dateOfVisitErrorMessage = pepResult;
      }

      return item;
    }

    /*
     if isIns only:
     - validate against insuranceStartDate
     -- iSD determined in claims-submission.js
     -- iSD accounts for waiting period which can vary by state / model law
     if neither isIns or isPep:
     - still validate that dateOfVisit is valid
     */
    const validDateResult = validateClaimsDate({
      dateStr: item.dateOfVisit,
      dateLabel: 'Date of visit',
      insuranceStartDateStr: insuranceStartDate,
      shouldValidateCoverage: isInsurance,
    });

    if (validDateResult !== true) {
      item.dateOfVisitErrorMessage = validDateResult;
    }

    return item;
  });

  if (dateListValidated.some(item => !!item.dateOfVisitErrorMessage)) {
    errors.dateList = {
      title: 'Date of visit',
      messages: dateListValidated.map(item => item.dateOfVisitErrorMessage),
    };
  }

  return dateListValidated;
}

function validateTotalAmountList({ totalAmountList, errors }) {
  const totalAmountListValidated = totalAmountList.map(item => {
    item.amountErrorMessage = '';
    item.amountError = false;

    if (!item.totalAmountClaimed) {
      item.amountErrorMessage = 'This field is required.';
    }

    if (isNaN(item.totalAmountClaimed)) {
      item.amountErrorMessage = 'Invalid value.';
    }

    return item;
  });

  if (totalAmountListValidated.some(item => !!item.amountErrorMessage)) {
    errors.amount = {
      title: 'Total amount claimed',
      messages: totalAmountListValidated.map(item => item.amountErrorMessage),
    };
  }
}

function validateClaimType({ isInsurance, isPreventiveEssentials, errors }) {
  if (!isInsurance && !isPreventiveEssentials) {
    errors.claimType = {
      title: 'Type of claim',
      message: 'Please choose a claim type.',
    };
  }
}

function validateDateOfIncident({
  selectedPet,
  dateListValidated,
  isInsurance,
  isPreventiveEssentials,
  activePolicy,
  insuranceStartDate,
  injuryDate,
  errors,
}) {
  if (isInsurance) {
    const newInsuranceStartDate = isPreventiveEssentials
      ? calculateAndFormatISODate(activePolicy.policy_effective_date, '-14d')
      : insuranceStartDate;

    const validDateResult =
      isPreventiveEssentials && getIsPuppy(selectedPet)
        ? true
        : validateClaimsDate({
            dateStr: injuryDate,
            dateLabel: 'Date of accident/illness',
            insuranceStartDateStr: newInsuranceStartDate,
          });

    if (validDateResult !== true) {
      errors.dateOfIncident = {
        title: 'Date of accident/illness',
        message: validDateResult,
      };
      return;
    }

    const firstValidVisitDate = dateListValidated
      .filter(
        ({ dateOfVisit, dateOfVisitErrorMessage }) =>
          dateOfVisit && !dateOfVisitErrorMessage
      )
      .sort((a, b) => new Date(a.dateOfVisit) - new Date(b.dateOfVisit))[0];

    if (
      !firstValidVisitDate ||
      dateIsAfter(injuryDate, firstValidVisitDate.dateOfVisit)
    ) {
      errors.dateOfIncident = {
        title: 'Date of accident/illness',
        message: 'Accident/illness date should be before date of visit',
      };
      return;
    }
  }
}

function validateCondition(
  { isInsurance, isOngoing, errors },
  { shouldValidateMultiVetAndOngoing }
) {
  if (
    shouldValidateMultiVetAndOngoing &&
    isInsurance &&
    typeof isOngoing !== 'boolean'
  ) {
    errors.newCondition = { title: 'Is this a new condition' };
  }
}

function validateVetReason({ vetVisitReason, errors }) {
  if (vetVisitReason === '') {
    errors.vetVisit = {
      title: 'Reason for vet visit',
      message: 'This field is required.',
    };
  }
}

export default function validateClaimSubmissionFields(
  {
    fileList,
    dateList,
    injuryDate,
    totalAmountList,
    vetVisitReason,
    isPreventiveEssentials,
    isInsuranceIllness,
    isInsuranceAccident,
    insuranceStartDate = null,
    medicalRecordsFileList = [],
    selectedVet,
    isMultiVet,
    isOngoing,
    selectedPet = {},
  },
  { shouldValidateMultiVetAndOngoing, shouldValidateClinic } = {}
) {
  const errors = {};
  const isInsurance = isInsuranceAccident || isInsuranceIllness;
  const activePlan = getActivePlan(selectedPet.plans);
  const activePolicy = getActivePolicy(selectedPet.policies);

  validateInvoices({ fileList, errors });
  validateMedicalRecords({ medicalRecordsFileList, errors });
  validateClinicSelection({ selectedVet, errors }, { shouldValidateClinic });
  validateMultipleClinics(
    { isInsurance, isMultiVet, errors },
    { shouldValidateMultiVetAndOngoing }
  );
  const dateListValidated = validateDateList({
    dateList,
    isPreventiveEssentials,
    isInsurance,
    activePlan,
    insuranceStartDate,
    errors,
  });
  validateTotalAmountList({
    totalAmountList,
    errors,
  });
  validateClaimType({ isInsurance, isPreventiveEssentials, errors });
  validateDateOfIncident({
    selectedPet,
    dateListValidated,
    isInsurance,
    isPreventiveEssentials,
    activePolicy,
    insuranceStartDate,
    injuryDate,
    errors,
  });
  validateCondition(
    { isInsurance, isOngoing, errors },
    { shouldValidateMultiVetAndOngoing }
  );
  validateVetReason({ vetVisitReason, errors });

  return {
    errors,
  };
}
